import AvatarImg from 'assets/images/avatar.png';
import ImagePopup from 'components/global/ImagePopup';
import RequestStatus from 'components/global/RequestStatus';
import { baseURL } from 'config/api';
import { useState } from 'react';

const UserDetails = ({ booking }) => {
    const [showImage , setShowImage] = useState(false);
    const [image , setImage] = useState(null)

    return (
        <div className='shadow-bg p-4'>
            <div>
                <h3 className="text-lg font-semibold text-primary">
                    User Details
                </h3>
            </div>
            <div className='mt-6'>
                <div className='flex items-center justify-center'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden flex items-center justify-center'>
                        <img 
                        src={baseURL + '/selfies/' + booking?.customer?.kyc?.selfie} 
                        alt="User selfie"
                        className='w-full h-full rounded-full hover:scale-110 duration-200 ease-in-out cursor-pointer'
                        onClick={() => {
                            setImage(baseURL + '/selfies/' + booking?.customer?.kyc?.selfie)
                            setShowImage(true);
                        }}
                        />
                    </div>
                </div>
                <div className='mt-8 flex flex-col gap-3'>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[120px]'>
                            Aadhaar Card :
                        </h6>
                        <span className='text-gray-600'>
                            ********{booking?.customer?.kyc?.aadhaarCardNumber?.slice(-4)}
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[120px]'>
                            Driving License :
                        </h6>
                        <span className='text-gray-600'>
                        ***********{booking?.customer?.kyc?.licenseCardNumber?.slice(-4)}
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[120px]'>
                            KYC Status :
                        </h6>
                        <span className='text-gray-600'>
                            <RequestStatus status={booking?.customer?.kyc?.status} />
                        </span>
                    </div>

                    <div>
                        
                    </div>
                </div>
            </div>

            {
                showImage && <ImagePopup setShowPopup={setShowImage} img={image} />
            }
        </div>
    )
}

export default UserDetails