import AvatarImg from 'assets/images/avatar.png';
import Heading from 'components/global/Heading';
import NameInput from 'components/global/NameInput';
import FormControl from 'components/global/form/FormControl';
import { baseURL } from 'config/api';
import { Form, Formik } from 'formik';
import {  useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { updateProfile } from 'redux/actions/authActions';
import isBase64 from 'utils/isBase64';
import profileValidations from 'validations/profileValidations';

const UpdateProfile = () => {
    const dispatch = useDispatch();
    const imgRef = useRef(null);
    const { user , updateLoading } = useSelector(state => state.auth);

    const initValues = {
        fullName : user?.fullName ,
        email : user?.email , 
        phone : user?.phone , 
        address : user?.address || '' ,
        city : user?.city || ''
    }
    
    const [image , setImage] = useState(user?.image ? baseURL + '/users/' + user?.image : AvatarImg);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            }
        }
    }

    const isFormNotChanged = (formData) => {
        let isImageChanged ;
        if(user?.image) {
            isImageChanged = image === baseURL + '/users/' + user?.image;
        }else {
            isImageChanged = image === AvatarImg;
        }
        return isImageChanged && JSON.stringify(initValues) === JSON.stringify(formData)
    }
    
    const handleSubmit = async (formData) => {
        if(isFormNotChanged(formData)) {
            return toast.error('You have not made any changes yet.')
        }
        if (isBase64(image)) {
            formData.image = image;
        }
        delete formData.email;
        delete formData.phone;
        dispatch(updateProfile(formData));
    }


    return (
        <div>
            <div className='flex items-center justify-between'>
                <Heading title='Update Profile' showIcon={false} />
            </div>
            <div className='shadow-bg mt-4  p-6'>
            <Formik
            initialValues={initValues}
            validationSchema={profileValidations}
            onSubmit={handleSubmit}
            >
                {
                    (formik) => {
                        return (
                            <Form className='shadow-bg sm:p-6 p-4 flex flex-col gap-4 mt-6'>
                                <div className='flex items-center justify-center flex-col mb-8'>
                                    <div className='w-[120px] h-[120px] rounded-full border'>
                                        <input 
                                        type="file" 
                                        accept='image/*'
                                        hidden 
                                        ref={imgRef} 
                                        onChange={handleFileChange}
                                        />
                                        <img 
                                        src={image} 
                                        alt={user?.username}
                                        className='w-full h-full rounded-full object-cover' 
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <button
                                        type='button'
                                        className="btn-primary py-1.5 px-10"
                                        onClick={() => imgRef?.current?.click() }
                                        >
                                            Profile Picture
                                        </button>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 sm:flex-row flex-col'>
                                    <FormControl 
                                    control='input'
                                    label='Full Name'
                                    placeholder='Ex : John Doe'
                                    name='fullName'
                                    formik={formik}
                                    />
                                    <FormControl 
                                    control='input'
                                    label='Email'
                                    name='email'
                                    formik={formik}
                                    readOnly
                                    disabled
                                    />
                                </div>
                                <div>
                                    <FormControl 
                                    control='input'
                                    type='number'
                                    label='Phone no'
                                    name='phone'
                                    formik={formik}
                                    readOnly
                                    disabled
                                    />
                                </div>
                                <div>
                                    <FormControl 
                                    control='input' 
                                    label='Address'
                                    placeholder='Ex : etc town , etc street , block etc house no'
                                    name='address'
                                    formik={formik}
                                    />
                                </div>
                                <div>
                                    <FormControl 
                                    control='input'
                                    label='City'
                                    placeholder='Ex : Kolkata'
                                    name='city'
                                    formik={formik}
                                    />
                                </div>
                                <div className='mt-4'>
                                    <button 
                                    className="btn-primary py-2 px-8"
                                    disabled={updateLoading || !formik.isValid}
                                    >
                                        {
                                            updateLoading 
                                            ? 
                                                <ClipLoader size={20} color='white' />
                                            : 
                                                'Save'
                                        }
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }

            </Formik>
            </div>
        </div>
    )
}

export default UpdateProfile