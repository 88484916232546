import Cam from 'components/bookingManagement/Cam'
import PickupPhotos from 'components/bookingManagement/bookingDetails/PickupPhotos'
import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Axios from 'config/api'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import toastError from 'utils/toastError'

const UploadPickUpPhotos = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const [loading , setLoading] = useState(false);
    const [pickedPhotos, setPickedPhotos] = useState({
        dashboardMeter : '' ,
        handOverKey : '' ,
        frontRightCorner: '',
        frontLeftCorner: '',
        fullFront: '',
        rearRightCorner: '',
        rearLeftCorner: '',
        fullRear: '',
        leftDoor: '',
        rightDoor: '',
        bootSpace: '',
        interiorFront: '',
        interiorBack: ''
    });


    const isAllValuesFilled = Object.values(pickedPhotos).every(value => value !== '');

    const updateBookingHandler = async () => {
        try {
            setLoading(true)
            const { data : { data : { message , booking } } } = await Axios.put(`/booking/upload-pickup-photos/${id}` , pickedPhotos , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });

            toast.success(message);
            navigate(`/booking-management/booking-details/${id}`);
            setLoading(false)
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <Layout>
            <div className='pb-12'>
                <div className="flex sm:items-center justify-between gap-2 sm:flex-row flex-col-reverse">
                    <div>
                        <Heading title='Upload Pickup Photos' showIcon={false} />
                        <p className='text-sm text-gray-600 mt-1'>
                            In order to continue please capture and upload all the photos mention below
                        </p>
                    </div>
                    <BackBtn />
                </div>
                <div className='grid 2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-6'>
                    <PhotoItem
                    title='Meter On Dashboard Photo'
                    setImage={setPickedPhotos}
                    name='dashboardMeter'
                    image={pickedPhotos.dashboardMeter}
                    />
                    <PhotoItem
                    title='Hand Over Key Photo'
                    setImage={setPickedPhotos}
                    name='handOverKey'
                    image={pickedPhotos.handOverKey}
                    />
                    <PhotoItem
                    title='Front Right Corner Photo'
                    setImage={setPickedPhotos}
                    name='frontRightCorner'
                    image={pickedPhotos.frontRightCorner}
                    />
                    <PhotoItem
                    title='Front Left Corner Photo'
                    setImage={setPickedPhotos}
                    name='frontLeftCorner'
                    image={pickedPhotos.frontLeftCorner}
                    />
                    <PhotoItem
                    title='Full Front Photo'
                    setImage={setPickedPhotos}
                    name='fullFront'
                    image={pickedPhotos.fullFront}
                    />
                    <PhotoItem
                    title='Rear Right Corner Photo'
                    setImage={setPickedPhotos}
                    name='rearRightCorner'
                    image={pickedPhotos.rearRightCorner}
                    />
                    <PhotoItem
                    title='Rear Left Corner Photo'
                    setImage={setPickedPhotos}
                    name='rearLeftCorner'
                    image={pickedPhotos.rearLeftCorner}
                    />
                    <PhotoItem
                    title='Full Rear Photo'
                    setImage={setPickedPhotos}
                    name='fullRear'
                    image={pickedPhotos.fullRear}
                    />
                    <PhotoItem
                    title='Left Door Photo'
                    setImage={setPickedPhotos}
                    name='leftDoor'
                    image={pickedPhotos.leftDoor}
                    />
                    <PhotoItem
                    title='Right Door Photo'
                    setImage={setPickedPhotos}
                    name='rightDoor'
                    image={pickedPhotos.rightDoor}
                    />
                    <PhotoItem
                    title='Boot Space Photo'
                    setImage={setPickedPhotos}
                    name='bootSpace'
                    image={pickedPhotos.bootSpace}
                    />
                    <PhotoItem
                    title='Interior Front Photo'
                    setImage={setPickedPhotos}
                    name='interiorFront'
                    image={pickedPhotos.interiorFront}
                    />
                    <PhotoItem
                    title='Interior Back Photo'
                    setImage={setPickedPhotos}
                    name='interiorBack'
                    image={pickedPhotos.interiorBack}
                    />
                </div>
            </div>
            {
                isAllValuesFilled && 
                <button className='btn-primary py-3 px-12' onClick={updateBookingHandler}>
                    {
                        loading 
                        ? 
                            <ClipLoader size={20} color='white' />
                        : 
                            'Update Booking'
                    }
                </button>
            }
        </Layout>
    )
}


const PhotoItem = ({ title , name , setImage , image }) => {
    const [showCam , setShowCam] = useState(false);
    return (
        <div 
        className='flex-1 w-full h-full border-2 hover:border-primary rounded-lg relative flex items-center justify-center'
        
        >
            {
                image 
                ? 
                    <img src={image} alt={title} className='w-full h-full object-cover rounded-lg' />
                : 
                    <div className='flex flex-col gap-3 sm:h-[300px] h-[250px] items-center justify-center'>
                        {title}
                        <button className="bg-primary text-white rounded-full text-sm  py-2 px-4" onClick={() => {
                            setShowCam(true)
                        }}>
                            Click Photo
                        </button>
                    </div>
            }
            {
                image && <div 
                className='absolute top-2 right-2 bg-white w-[40px] h-[40px] rounded-full flex items-center justify-center text-black border border-primary cursor-pointer hover:bg-primary hover:text-white'
                onClick={() => setShowCam(true)}
                >
                    <i className="uil uil-pen"></i>
                </div>
            }

            {
                showCam && <Cam name={name} setImage={setImage} setShowCam={setShowCam} />
            }
        </div>
    )
}

export default UploadPickUpPhotos