import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';

import AboutBooking from 'components/bookingManagement/bookingDetails/AboutBooking';
import BookingInfo from 'components/bookingManagement/bookingDetails/BookingInfo';
import CarDetails from 'components/bookingManagement/bookingDetails/CarDetails';
import UserDetails from 'components/bookingManagement/bookingDetails/UserDetails';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import fetcher from 'utils/fetcher';
import { useSelector } from 'react-redux';
import Axios from 'config/api';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import ItemNotFound from 'components/global/ItemNotFound';
import PickupPhotos from 'components/bookingManagement/bookingDetails/PickupPhotos';
import DropOffPhotos from 'components/bookingManagement/bookingDetails/DropOffPhotos';

const BookingDetails = () => {
    const { id } = useParams();
    const { user } = useSelector(state => state.auth);
    const [status , setStatus] = useState('');
    const [booking , setBooking] = useState('');

    const { isLoading , data } = useQuery('fetch-booking' , () => {
        return fetcher(`/booking/${id}` , user);
    });


    useEffect(() => {
        if(data) {
            setBooking(data?.data?.data?.doc);
            setStatus(data?.data?.data?.doc?.status);
        }
    } , [data]);

   


    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between gap-4 sm:flex-row flex-col-reverse">
                    <Heading title='Booking Details' showIcon={false} />
                    <BackBtn />
                </div>
                {
                    isLoading
                    ? 
                        <Loader />
                    : 
                    booking 
                    ? 
                        <div className='flex gap-6 mt-12'>
                            <div className='flex-1 flex flex-col gap-6'>
                                <div>
                                    <AboutBooking 
                                    status={status}
                                    setStatus={setStatus}
                                    booking={booking}
                                    setBooking={setBooking}
                                    />
                                </div>
                                <div>
                                    <CarDetails
                                    booking={booking}
                                    />
                                </div>
                                <div>
                                    <BookingInfo 
                                    booking={booking}
                                    />
                                </div>
                                
                            </div>
                            <div className='flex-[0.3] flex flex-col gap-4'>
                                <UserDetails
                                booking={booking}
                                />
                            </div>
                        </div>
                    : 
                        <ItemNotFound message='Booking not found.' />
                }

            </div>
            {
                booking && booking?.pickupPhotos && Object.values(booking?.pickupPhotos)?.length > 0 && 
                <PickupPhotos booking={booking} />
            }
            {
                booking && booking?.dropOffPhotos && Object.values(booking?.dropOffPhotos)?.length > 0 && 
                <DropOffPhotos booking={booking} />
            }
            
        </Layout>
    )
}

export default BookingDetails