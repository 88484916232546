import RequestStatus from 'components/global/RequestStatus';
import Pagination from 'components/global/pagination';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { setCurrentPage } from 'redux/reducers/withdrawReducer';
import formatAmount from 'utils/formatAmount';

const WithdrawHistoryTable = () => {
    const navigate = useNavigate();
    const [selectedItem , setSelectedItem] = useState(null);
    const { docs , currentPage , pages , updateLoading } = useSelector(state => state.withdraw);



    return (
        <div className='shadow-bg '>
            <div className="box-shadow mt-4 mb-4">
                <Table className="w-full table-auto overflow-x-auto ">
                    <Thead className="border-b text-sm">
                        <Tr className='bg-gradient text-white'>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Bank
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Amount
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Date
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Status
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Description
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody className='text-sm'>
                        {
                            docs?.map(item => (
                                <Tr key={item} className='border-b border-b-gray-300'>
                                    <Td className={`  px-6 py-4 whitespace-nowrap sm:text-center text-right flex flex-col gap-1`}>
                                        <span>{item?.withdrawBank?.bankName}</span>
                                        <span>{item?.withdrawBank?.accountHolder}</span>
                                        <span>{item?.withdrawBank?.accountNo}</span>
                                        <span>{item?.withdrawBank?.IFSC_code}</span>
                                    </Td>
                                    <Td className={`px-6 py-4 whitespace-nowrap sm:text-center text-right`}>
                                        ₹ {formatAmount(item?.withdrawAmount)}
                                    </Td>
                                    <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                        {moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}
                                    </Td>
                                    <Td className={`  px-6 py-4 whitespace-nowrap sm:text-center text-right`}>
                                        <div className="flex items-center sm:justify-center justify-end">
                                            <RequestStatus status={item?.status} />
                                        </div>
                                    </Td>
                                    <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                        {item?.description?.length > 0 ? item?.description : '----'}
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </div>
            <Pagination
            pageCount={pages}
            currentPage={currentPage}
            setPage={setCurrentPage}
            />
        </div>
    )
}

export default WithdrawHistoryTable