import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import TextError from './TextError';

const MultiFileInput = ({ label = 'Add Images', field , form , meta }) => {
    const imgRef = useRef(null);

    const handleFileChange = e => {
        const files = e.target.files;

        if (!files) {
            toast.error("Please select an image.");
            return;
        }

        let newFieldValues = [];

        Array.from(files)?.forEach((file) => {
            const maxSize = 5 * 1024 * 1024;
            if (file.size > maxSize) {
                toast.error("Image size exceeds the maximum limit of 2MB.");
                e.target.value = "";
                return;
            }
            
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                newFieldValues.push(reader.result);
    
                // Check if all files are processed
                if (newFieldValues.length === files.length) {
                    form.setFieldValue(field.name, [...field.value, ...newFieldValues]);
                }
            }
        });

    };

    return (
        <div>
            <div className='flex flex-col gap-2'>
                <label className='font-semibold text-gray-600'>
                    {label}
                </label>
                <input
                    type="file"
                    accept='image/*'
                    ref={imgRef}
                    onChange={handleFileChange}
                    hidden
                    multiple
                />
                <div className='flex flex-wrap items-center gap-4'>
                    {

                        field?.value?.length > 0 && field?.value?.map((item, i) => (
                            <div
                                key={i}
                                className='relative'
                            >
                                <img
                                    src={item}
                                    alt='Screenn'
                                    className='w-[250px] h-[150px] object-cover rounded-md'
                                />
                                <div
                                    className='absolute top-1 right-2 text-red-500  cursor-pointer text-xl'
                                    onClick={() => {
                                        form.setFieldValue(field.name , field?.value?.filter(i => i !== item))
                                    }}
                                >
                                    <i className="uil uil-trash"></i>
                                </div>
                            </div>
                        ))
                    }
                    <div 
                    className={`w-[150px] h-[150px] rounded-md border flex items-center justify-center text-5xl  text-primary cursor-pointer
                    ${meta.touched && meta.error ? 'border-red' : 'border-primary'}
                    `}
                        onClick={() => imgRef.current.click()}
                    >
                        <i className="uil uil-plus"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FormikMultiFileInput = ({ label, name, formik, ...rest }) => (
    <div className='flex-1 flex flex-col gap-2'>
        <Field name={name}>
            {({ field , form , meta }) =>{
                return (
                    <MultiFileInput
                        label={label}
                        field={field}
                        form={form}
                        meta={meta}
                        {...rest}
                    />
                )
            }}
        </Field>
        <ErrorMessage component={TextError} name={name} />
    </div>
);

export default FormikMultiFileInput;
