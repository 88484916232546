import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import WithdrawHistoryTable from './WithdrawHistoryTable'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/withdrawReducer'
import fetcher from 'utils/fetcher'
import Loader from 'components/global/Loader'
import ItemNotFound from 'components/global/ItemNotFound'

const WithdrawHistory = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , pages  } = useSelector(state => state.withdraw);

    const queryKey = ['fetch-my-withdraw-history' , currentPage ];
    const { isLoading , data  } = useQuery(queryKey , () => {
        return fetcher(`/withdraw/my?page=${currentPage}` , user);
    }) ;

    useEffect (() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setDocs(docs))
            dispatch(setDocsCount(docsCount))
            dispatch(setPages(pages))
            dispatch(setCurrentPage(page));
        }
    }, [data]);


    return (
        <Layout bgGray={true}>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <Heading title='Withdraw History' showIcon={false} />
                    <BackBtn />
                </div>
                <div className='flex flex-col gap-5 mt-2'>
                    {
                        isLoading
                        ? 
                            <Loader />
                        : 
                        docs?.length > 0 
                        ?
                            <>
                                <WithdrawHistoryTable />
                            </>
                        :  
                            <ItemNotFound message='No withdraw history found.' /> 
                    } 
                </div>
            </div>
        </Layout>
    )
}

export default WithdrawHistory