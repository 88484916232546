import * as Yup from 'yup';

// Regular expression for IFSC code validation
const IFSC_REGEX = /^[A-Za-z]{4}\d{7}$/;

const bankValidations = Yup.object().shape({
  bankName: Yup.string()
    .required('Bank name is required')
    .min(3, 'Bank name must be at least 3 characters long')
    .max(50, 'Bank name can be at most 50 characters long'),
  accountNo: Yup.string()
    .required('Account number is required')
    .matches(/^\d{9,18}$/, 'Account number must be between 9 and 18 digits'),
  accountHolder: Yup.string()
    .required('Account holder name is required')
    .min(3, 'Account holder name must be at least 3 characters long')
    .max(50, 'Account holder name can be at most 50 characters long'),
  IFSC_code: Yup.string()
    .required('IFSC code is required')
    .matches(IFSC_REGEX, 'Invalid IFSC code format'),
});

export default bankValidations;