import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import Pagination from 'components/global/pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BookingItem from 'components/bookingManagement/BookingItem';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import { setCurrentPage, setDocs, setDocsCount, setDuration, setPages, setStatus } from 'redux/reducers/bookingReducer';
import toastError from 'utils/toastError';

const statuses = ['pending' , 'accepted' , 'completed' , 'cancelled' , 'declined'];

const Bookings = () => {
    const dispatch = useDispatch();
    const [search , setSearch] = useState('');

    const { 
        status , duration , currentPage , pages , docsCount ,
        docs : bookings
    } = useSelector(state => state.booking)
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-bookings' , status , duration , currentPage, search ];
    const url = `/booking/seller-bookings/${user?._id}?page=${currentPage}&duration=${duration}&status=${status}&keyword=${search}`;
    
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(url , user)
    });

    useEffect(() => {
        if(data) {
            dispatch(setDocs(data?.data?.data?.docs));
            dispatch(setCurrentPage(data?.data?.data?.page));
            dispatch(setPages(data?.data?.data?.pages));
            dispatch(setDocsCount(data?.data?.data?.docsCount));
        }
    }, [data])

    const searchFetcher = (value) => {
        setSearch(value); 
    }

    return (
        <Layout>
            <div className='pb-12'>
                <div>
                    <BackBtn />
                </div>
                <div className='flex items-center justify-between gap-4 mt-4'>
                    <div className='flex items-cener gap-4'>
                        <Heading title='All Bookings' showIcon={false} />
                        <div className="docsCount">
                            {docsCount}
                        </div>
                    </div>
                    <div className='flex items-center gap-6'>
                        <Search 
                        fetcher={searchFetcher}
                        />
                        <div className='flex items-center gap-2'>
                            <label className='font-semibold'>Status</label>
                            <select
                            className='select-box'
                            onChange={(e) => dispatch(setStatus(e.target.value))}
                            value={status}
                            >
                                <option value=''>All</option>
                            {
                                statuses?.map(item => (
                                    <option 
                                    key={item}
                                    value={item}>
                                        {item}
                                    </option>
                                ))
                            }
                            </select>
                        </div>
                        <div className='flex items-center gap-2'>
                            <label className='font-semibold'>Duration</label>
                            <select 
                            className='select-box'
                            onChange={e => dispatch(setDuration(e.target.value))}
                            value={duration}
                            >
                                <option value=''>All</option>
                                <option value='today'>Today</option>
                                <option value='yesterday'>Yesterday</option>
                                <option value='week'>This Week</option>
                                <option value='month'>This Month</option>
                                <option value='year'>This Year</option>
                            </select>
                        </div>
                    </div>
                    
                </div>
                
                <div className='mt-14'>
                    {
                        isLoading 
                        ? 
                            <Loader />
                        : 
                        bookings?.length > 0 
                        ? 
                            <div className='flex flex-col gap-6'>
                                {
                                    bookings?.map((item , i) => (
                                        <BookingItem 
                                        key={item?._id} 
                                        booking={item}
                                        />
                                    ))
                                }
                                <Pagination 
                                pageCount={pages}
                                currentPage={currentPage}
                                setPage={setCurrentPage}
                                />
                            </div>
                        : 
                            <ItemNotFound />
                    } 
                </div>
            </div>
        </Layout>
    )
}

export default Bookings