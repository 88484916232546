import Cards from 'components/dashboard/Cards';
import TopCustomers from 'components/dashboard/TopCustomers';
import TopSellings from 'components/dashboard/TopSellings';
import TotalRevenue from 'components/dashboard/TotalRevenue';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'redux/reducers/authReducer';
import fetcher from 'utils/fetcher';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [stats , setStats] = useState('');
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-dashboard-details']
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user/seller-dashboard/${user?._id}` , user);
    });

    useEffect(() => {
        if(data) {
            setStats(data?.data?.data);
            const { user : _user } = data?.data?.data;
            dispatch(setUser({
                ..._user , 
                token : user?.token 
            }));
            localStorage.setItem('user' , 
                JSON.stringify({ ..._user , token : user?.token })
            );
        }
    }, [data])
   
    return (
        <Layout>
            {
                isLoading 
                ? 
                    <Loader />
                : 
                <div className='flex flex-col gap-8'>
                    <div>
                        <Cards 
                        totalBookings={stats?.totalBookings || 0}
                        totalCars={stats?.totalCars || 0}
                        />
                    </div>
                    {/* <div>
                        <TotalRevenue />
                    </div> */}
                    {/* <div>
                        <OrdersList />
                    </div> */}
                    <div>
                        <TopSellings 
                        topCars={stats?.topCars}
                        />
                    </div>
                    <div>
                        <TopCustomers 
                        topCustomers={stats?.topCustomers}
                        />
                    </div>
                </div>

            }
        </Layout>
    )
}

export default Dashboard