import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import PlacesInput from 'components/global/PlacesInput';
import FormControl from 'components/global/form/FormControl';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { car_edit, car_getDetails } from 'redux/actions/carActions';
import carValidation from 'validations/carValidations';
import LazyLoad from 'react-lazyload';
import Axios, { baseURL } from 'config/api';
import isBase64 from 'utils/isBase64';
import toastError from 'utils/toastError';
import Loader from 'components/global/Loader';
import editCarValidation from 'validations/editCarValidations';


const vehicleTypes = ['Sedan', 'SUV', 'Hatchback', 'Convertible', 'Luxury', 'Sports', 'Electric', 'Hybrid', 'Van', 'Truck', 'Minivan'];
const fuelTypes = [ "gasoline", "diesel", "electric" , "CNG" , "petrol" ]
const daysOfWeek = ['sunday' , 'monday' , 'tuesday' , 'wednesday' , 'thursday' ,'friday' ,'saturday']

const initValues = {
    name : "" ,
    make : "",
    model: "",
    year: '',
    vehicleType : 'Sedan' ,
    transmissionType: "automatic",
    fuelType: "electric",
    mileage: '',
    licensePlate: "",
    seats: 5,
    doors: 4,
    airConditioning: true,
    audioSystem: "",
    rentPerHour: '',
    pickupInstructions: "" ,
    isActive : true ,
    RC :'' ,
    POC :'' ,
    insurance :'' ,
    images : [] , 
    hasDriver : false ,
    selfDrive : true ,
    driverRentPerDay : 0
}

const EditCar = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ carLocation , setCarLocation ] = useState({
        address :'' ,
        lat :'' , 
        lng :'' ,
        error : ''
    });
    const [ dropOffLocation , setDropOffLocation ] = useState({
        address :'' ,
        lat :'' , 
        lng :'' ,
        error : ''
    });
    const [carData , setCarData] = useState(initValues);
    const [uploadedImages , setUploadedImages] = useState([]);
    const [deleteLoading , setDeleteLoading] = useState(false);
    
    const { user } = useSelector(state => state.auth)
    const { docDetails : carDetails , loading , updateLoading  } = useSelector(state => state.car);

    useEffect(() => {
        if(id) {
            dispatch(car_getDetails(id))
        }
        return () => setCarData('')
    }, [id]);

    useEffect(() => {   
        if(carDetails) {
            setCarData(prev => ({
                ...carDetails ,
                RC : carDetails?.RC ? baseURL + '/RC/' + carDetails?.RC : '' ,
                POC : carDetails?.POC ? baseURL + '/POC/' + carDetails?.POC : '' ,
                insurance : carDetails?.insurance ? baseURL + '/insurance/' + carDetails?.insurance : '' , 
                images : [] , 
                location : {}
            }));
            setUploadedImages(() => ([...carDetails?.images]));
        }
        return () => setCarData('')
    }, [carDetails]);

    
    const handleSubmit = (formData) => {
        const data = {
            ...formData ,
        }
        delete data.RC; delete data.POC ; delete data.insurance; delete data.location;
        
        if(data?.images?.length === 0) {
            delete data.images;
        }
        if(isBase64(formData?.RC)) {
            data.RC = formData?.RC;
        }
        if(isBase64(formData?.POC)) {
            data.POC = formData?.POC;
        }
        if(isBase64(formData?.insurance)) {
            data.insurance = formData?.insurance;
        }
        if(carLocation?.address?.length > 0 ){
            data.location = {
                address : carLocation?.address,
                coordinates : [parseFloat(carLocation?.lng) , parseFloat(carLocation?.lat)]
            }
        }
        if(dropOffLocation?.address?.length > 0 ){
            data.dropOffLocation = {
                address : dropOffLocation?.address,
                coordinates : [parseFloat(dropOffLocation?.lng) , parseFloat(dropOffLocation?.lat)]
            }
        }

        dispatch(car_edit( id , data , navigate))
    }

    const deleteUploadedImage = async (item) => {
        if(window.confirm('Are you sure? You want to delete this image?')) {
            setDeleteLoading(true)
            try {
                const { data : { data : { message } } } = await Axios.delete(`/car/${id}/delete-image/${item}` , {
                    headers : {
                        Authorization : `Bearer ${user.token}`
                    }
                } );
                toast.success(message)
                setUploadedImages(prev => prev?.filter(img => img !== item))
                setDeleteLoading(false)
            } catch (err) {
                setDeleteLoading(false)
                toastError(err)
            }
        }
    }

    
    return (
        <Layout>
            <div className='pb-12'>
                <div className='flex justify-between items-center'>
                    <Heading title='Edit Car' />
                    <BackBtn />
                </div>
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                    carData 
                    ? 
                        <div className='shadow-bg p-4 mt-8'>
                            <Formik
                            initialValues={carData}
                            validationSchema={editCarValidation}
                            onSubmit={handleSubmit}
                            enableReinitialize
                            >
                                {
                                    (formik) => { 
                                        console.log({ formik })
                                        return (
                                            <Form
                                            className='flex flex-col gap-4'
                                            >
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='input'
                                                    label='Name*'
                                                    placeholder='Ex : Enter car name'
                                                    name='name'
                                                    formik={formik}
                                                    />
                                                   <FormControl
                                                    control='input'
                                                    type='number'
                                                    label='Rent Per Hour*'
                                                    placeholder='Ex : 100'
                                                    name='rentPerHour'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <div className='w-full flex-1'>
                                                        <PlacesInput
                                                        label='Pickup Location*'
                                                        location={carLocation}
                                                        setLocation={setCarLocation}
                                                        />
                                                    </div>
                                                    <div className='w-full flex-1'>
                                                        <PlacesInput
                                                        label='Drop Off Location*'
                                                        location={dropOffLocation}
                                                        setLocation={setDropOffLocation}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='input'
                                                    label='Make*'
                                                    placeholder='Ex : Toyota'
                                                    name='make'
                                                    formik={formik}
                                                    />
                                                    <FormControl
                                                    control='input'
                                                    label='Model*'
                                                    placeholder='Ex : Camry'
                                                    name='model'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='select'
                                                    label='Vehicle Type*'
                                                    name='vehicleType'
                                                    options={vehicleTypes.map(item => ({
                                                        key : item , value : item 
                                                    }))}
                                                    formik={formik}
                                                    />
                                                    <FormControl
                                                    control='select'
                                                    label='Transmission*'
                                                    name='transmissionType'
                                                    options={['automatic', 'manual'].map(item => ({
                                                        key : item , value : item 
                                                    }))}
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='input'
                                                    type='number'
                                                    label='Year*'
                                                    placeholder='Ex : 2010'
                                                    name='year'
                                                    formik={formik}
                                                    />
                                                    <FormControl
                                                    control='select'
                                                    label='Fuel Type*'
                                                    name='fuelType'
                                                    options={fuelTypes.map(item => ({
                                                        key : item , value : item 
                                                    }))}
                                                    formik={formik}
                                                    />                                   
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='input'
                                                    label='License Plate*'
                                                    placeholder='Ex : ABC123'
                                                    name='licensePlate'
                                                    formik={formik}
                                                    />
                                                    <FormControl
                                                    control='input'
                                                    label='Mileage*'
                                                    placeholder='Car mileage'
                                                    name='mileage'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='input'
                                                    type='number'
                                                    label='Seats*'
                                                    placeholder='Ex : 5'
                                                    name='seats'
                                                    formik={formik}
                                                    />
                                                    <FormControl
                                                    control='input'
                                                    type='number'
                                                    label='Doors*'
                                                    placeholder='Ex : 4'
                                                    name='doors'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='select'
                                                    label='Air Conditioning (optional)'
                                                    name='airConditioning'
                                                    options={[
                                                        { key : 'Yes' , value : true } ,
                                                        { key : 'No' , value : false } ,
                                                    ]}
                                                    formik={formik}
                                                    />   
                                                    <FormControl
                                                    control='input'
                                                    label='Audio System (optional)'
                                                    placeholder='Ex : CD Player, Bluetooth'
                                                    name='audioSystem'
                                                    formik={formik}
                                                    />   
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='toggle'
                                                    label='Active For Rent*'
                                                    name='isActive'
                                                    formik={formik}
                                                    />  
                                                    <div className="flex-1">
                                                        <FormControl
                                                        control='toggle'
                                                        label='With Driver'
                                                        name='hasDriver'
                                                        formik={formik}
                                                        />   
                                                    </div>
                                                    <div className="flex items-end justify-end">
                                                        <FormControl
                                                        control='toggle'
                                                        label='Self Drive'
                                                        name='selfDrive'
                                                        formik={formik}
                                                        />  
                                                    </div> 
                                                </div>
                                                {
                                                    formik.values.hasDriver &&
                                                    <div>
                                                        <FormControl
                                                        control='input'
                                                        label='Driver Rent Per Day'
                                                        placeholder='Please enter driver per day rent'
                                                        name='driverRentPerDay'
                                                        formik={formik}
                                                        />   
                                                    </div>
                                                }
                                                <div>
                                                    <FormControl
                                                    label='Pickup Instructions (optional)'
                                                    control='textarea'
                                                    name='pickupInstructions'
                                                    placeholder='Pickup instructions for customer'
                                                    formik={formik}
                                                    />
                                                    <div className='text-sm mt-1 text-gray-600 font-semibold text-right'>
                                                        <span>
                                                            {formik?.values?.pickupInstructions?.length}/400
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='file'
                                                    label={'Add RC (Registration Certificate)*'}
                                                    name='RC'
                                                    formik={formik}
                                                    />
                                                    <FormControl
                                                    control='file'
                                                    label={'Add PUC*'}
                                                    name='POC'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='flex gap-4 sm:flex-row flex-col'>
                                                    <FormControl
                                                    control='file'
                                                    label={'Add Insurance*'}
                                                    name='insurance'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='flex flex-wrap items-center gap-4 mt-6'>
                                                    {
                                                        uploadedImages?.length > 0 && uploadedImages?.map((item,i) => (
                                                            <div
                                                            key={i}
                                                            className='relative'
                                                            >
                                                                <LazyLoad height={200}>
                                                                    <img
                                                                    src={baseURL + '/cars/' + item}
                                                                    alt='Screenn'
                                                                    className='w-[250px] h-[150px] object-cover rounded-md' 
                                                                    />
                                                                </LazyLoad>
                                                                <button 
                                                                className='absolute top-1 right-2 text-red-500  cursor-pointer text-xl'
                                                                onClick={() => {
                                                                    deleteUploadedImage(item)
                                                                }}
                                                                disabled={deleteLoading}
                                                                >
                                                                    {
                                                                        deleteLoading
                                                                        ?
                                                                            <ClipLoader size={15} color='white' />
                                                                        : 
                                                                            <i className="uil uil-trash"></i>
                                                                    }
                                                                </button>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div>
                                                    <FormControl
                                                    control='multi-file'
                                                    label={'Add More Images*'}
                                                    name='images'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <button 
                                                    className="btn-primary py-2 px-12"
                                                    disabled={updateLoading}
                                                    >
                                                        {
                                                            updateLoading
                                                            ?
                                                                <ClipLoader size={20} color='white' />
                                                            : 
                                                                'Save'
                                                        }
                                                    </button>
                                                </div>

                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    : 
                        ''
                }
            </div>
        </Layout>
    )
}

export default EditCar;