import AddUnAvailabilityPopup from 'components/carsManagement/AddUnAvailabilityPopup'
import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import Axios from 'config/api'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { car_getDetails } from 'redux/actions/carActions'
import { setDocDetails } from 'redux/reducers/carReducer'
import confirmBox from 'utils/confirmBox'
import toastError from 'utils/toastError'


const ManageAvailability = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [deleteLoading , setDeleteLoading] = useState(false);
    const [selectedId , setSelectedId] = useState(null);
    const { user } = useSelector(state => state.auth);

    const [showPopup , setShowPopup] = useState(false);
    const { docDetails : carDetails , loading  } = useSelector(state => state.car);

    useEffect(() => {
        if(id) {
            dispatch(car_getDetails(id));
        }
    }, [id]);

    const deleteUnavailabilityHandler = async (unavailabilityId) => {
        
        const message = 'Are you sure? You want to delete these dates?';
        const onYesClick = async () => {
            setDeleteLoading(true);
            try {
                const { data : { data : { doc , message } } } = await Axios.delete(`/car/delete-unavailability-dates/${id}/${unavailabilityId}` , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                toast.success(message);
                dispatch(setDocDetails(doc));
                setDeleteLoading(false)
            } catch (error) {
                toastError(error);
                setDeleteLoading(false);
            }
        }
        confirmBox({ message , onYesClick })
    }


    return (
        <Layout>
            <BackBtn />
            <div className="flex items-center justify-between">
                <Heading title={'Manage Car Availability'} showIcon={false} />
                <div>
                    <button 
                    className="btn-primary py-2 px-6"
                    onClick={() => setShowPopup(true)}
                    >
                        <i className="uil uil-plus"></i>
                        <span>Add New</span>
                    </button>
                </div>
                {/* <BackBtn /> */}
            </div>
            <div className='mt-4'>
                {
                    loading
                    ? 
                        <Loader />
                    :  
                    carDetails?.unavailableDates?.length > 0 
                    ?
                        <div className='grid grid-cols-1 gap-4'>
                            {
                                carDetails?.unavailableDates?.map(item => (
                                    <div className="shadow-bg px-4 py-8 relative flex items-center justify-between">
                                        <div className='flex flex-col gap-1'>
                                            <h6 className='font-medium'>Start Date</h6>
                                            <p>
                                                {moment(item?.startDate).format('DD MMM YYYY')}
                                            </p>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <h6 className='font-medium'>End Date</h6>
                                            <p>
                                                {moment(item?.endDate).format('DD MMM YYYY')}
                                            </p>
                                        </div>
                                        {
                                            deleteLoading && selectedId === item?._id 
                                            ? 
                                                <ClipLoader size={20} />
                                            : 
                                            <button 
                                            className='accept p-4' 
                                            title='Delete Unavailability'
                                            disabled={deleteLoading}
                                            onClick={() => {
                                                setSelectedId(item?._id);
                                                deleteUnavailabilityHandler(item?._id)
                                            }}
                                            >
                                                <i className="uil uil-trash"></i>
                                            </button>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    : 
                        <ItemNotFound message='No Unavailability dates added yet.' />

                }
            </div>
            {
                showPopup && <AddUnAvailabilityPopup setShowPopup={setShowPopup} showPopup={showPopup} />
            }

        </Layout>
    )
}

export default ManageAvailability