import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import App from './App';
import DrawerContextProvider from 'context/DrawerContext';

import { QueryClientProvider , QueryClient } from 'react-query';

import './App.css';
import './utilities.css';

import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-accordion-comp/dist/styles.css";


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Provider } from 'react-redux';
import store from 'redux/store';

const queryClient = new QueryClient();

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
            console.error('Error registering Service Worker:', error);
        });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}> 
        <Router>
            <Provider store={store} >
                <DrawerContextProvider>
                    <App />
                </DrawerContextProvider>
            </Provider>
        </Router>
    </QueryClientProvider>
);

