import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import carReducer from "./reducers/carReducer";
import bookingReducer from "./reducers/bookingReducer";
import notificationReducer from "./reducers/notificationReducer";
import withdrawReducer from "./reducers/withdrawReducer";


const store = configureStore({
    reducer: {
        auth : authReducer , 
        car : carReducer ,
        booking : bookingReducer ,
        notification : notificationReducer , 
        withdraw : withdrawReducer , 
    },
});

export default store;