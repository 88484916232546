import React, { useEffect, useRef, useState } from 'react';
import Webcam from "react-webcam";

function Capture({ setImage , name , setShowCam  }) {
    const webcamRef = React.useRef(null);
    const [imageData, setImageData] = useState(null);




    const captureImage = React.useCallback(
        () => {
          const imageSrc = webcamRef.current.getScreenshot();
          setImageData(imageSrc)
        },
        [webcamRef]
    );

    const doneHandler = () => {
        setImage(prev => ({...prev , [name] : imageData }));
        setShowCam(false);
    }

    const cancelHandler = () => {
        setImageData('');
    }

    return (
        <div className='bg-slate-700 fixed z-50 top-0 left-0 w-full h-screen'>
            <div className='flex items-center justify-center w-full h-full flex-col gap-4'>
                {
                    imageData 
                    ? 
                        <img src={imageData} width="400" height="300" autoPlay />
                    :
                        <div className='w-[400px] h-[300px] border-2 border-gray-500 '>
                            <Webcam
                                audio={false}
                                height={300}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={400}
                                videoConstraints={{
                                    width: 400,
                                    height: 300,
                                    facingMode: "user"
                                }}
                            />
                        </div>
                }


                {
                imageData 
                ?
                    <div>
                        <button 
                        className="btn-green-500 rounded-full w-[40px] h-[40px] text-white flex items-center justify-center py-2 px-4 text-xl" 
                        onClick={doneHandler}
                        >
                            <i className="uil uil-tick"></i>
                        </button>
                        <button 
                        className='bg-red-500 text-white rounded-lg py-2 px-4'
                        onClick={cancelHandler}
                        >
                            Cancel
                        </button>
                    </div>
                : 
                    <button className='btn-primary py-2 px-4' onClick={captureImage}>
                        Capture Image
                    </button>
            }

            </div>
        </div>
    );
}

export default Capture;
