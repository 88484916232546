import NameInput from 'components/global/NameInput';
import PasswordInput from 'components/global/PasswordInput';
import ValidateInput from 'components/global/ValidateInput';
import FormControl from 'components/global/form/FormControl';
import { baseURL } from 'config/api';
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { register } from 'redux/actions/authActions';
import registerValidations from 'validations/registerValidation';


const initValues = {
    fullName : '' ,
    email : '' ,
    phone : '',
    password : ''
}

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isEmailValid , setIsEmailValid] = useState(false);
    const [isPhoneValid , setIsPhoneValid] = useState(false);
    const [isTermAccepted , setIsTermsAccepted] = useState(false);

    const { loading } = useSelector(state => state.auth);

    const [data , setData] = useState(initValues);

    const handleSubmit = async formData => {
        dispatch(register({ ...formData , phone : formData?.phone?.toString() } , navigate))
    }
    

    return (
        <div className='w-full min-h-screen fixed top-0 left-0  flex items-center justify-center p-4 bg-gradient'
        >
            <div className='shadow-bg p-4 md:w-[600px] sm:w-[500px] w-full'>
                <center>
                    <div className='flex items-center justify-center border-b pb-4 text-gradient text-2xl font-bold'>
                        Rent N Drive                       
                    </div>
                    <h1 className='text-2xl font-bold mt-4'>Sign Up</h1>
                    <p className='text-sm text-grayText mt-1'>Create Seller Account</p>
                </center>
                <Formik
                initialValues={initValues}
                validationSchema={registerValidations}
                onSubmit={handleSubmit}
                >
                    {
                        formik => {
                            return (
                                <Form
                                className='flex flex-col gap-2 mt-8'
                                >
                                    <FormControl
                                    control='input'
                                    label='Full Name'
                                    placeholder='Enter your name (as per Adhar)'
                                    name='fullName'
                                    formik={formik}
                                    />
                                    <FormControl
                                    control='input'
                                    type='email'
                                    label='Email'
                                    placeholder='Enter your email'
                                    name='email'
                                    formik={formik}
                                    />
                                    <FormControl
                                    control='input'
                                    type='number'
                                    label='Phone Number'
                                    placeholder='Enter your phone number'
                                    name='phone'
                                    formik={formik}
                                    />
                                    <FormControl
                                    control='password'
                                    label='Password'
                                    placeholder='Ex : ******** '
                                    name='password'
                                    formik={formik}
                                    />
                                    <div className='flex items-start gap-2 mt-4'>
                                        <input 
                                        type="checkbox" 
                                        className='mt-[5px]'
                                        id='termsOfService'
                                        onChange={e => {
                                            if(e.target.checked) {
                                                setIsTermsAccepted(true)
                                            }else {
                                                setIsTermsAccepted(false)
                                            }
                                        }}
                                        required
                                        />
                                        <label 
                                        htmlFor='termsOfService'
                                        className='text-sm'
                                        >
                                            By checking this box, you are agreeing to our <a href={`${baseURL}/agreements/vendor.pdf`} target='_blank' className='underline text-blue-400'>terms of service</a>
                                        </label>
                                    </div>
                                    <div className='mt-2'>
                                        <button
                                        type='submit' 
                                        className="btn-primary mt-2 py-3 sm:px-12 px-6 w-full"
                                        disabled={loading || !isTermAccepted || !formik.isValid}
                                        >
                                            {
                                                loading 
                                                ? 
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Sign up'
                                            }
                                        </button>
                                        <div className='text-sm text-dark mt-6 text-center flex items-center justify-center gap-1'>
                                            <span>Already have an account ? </span> 
                                            <button 
                                            type='button'
                                            className='text-gradient'
                                            onClick={() => navigate('/login')}
                                            disabled={loading}
                                            >
                                                Sign in
                                            </button>
                                        </div>
                                    </div>

                                </Form>
                            )
                        }
                    }
                </Formik>
                {/* <form 
                className='flex flex-col gap-2 mt-8'
                onSubmit={handleSubmit}
                >
                    <NameInput 
                    label='Full Name'
                    placeholder='Enter your name as per Aadhaar Card'
                    name='fullName'
                    data={data}
                    setData={setData}
                    required
                    minLength={3}
                    maxLength={100}
                    />
                    <ValidateInput 
                    type='email'
                    label='Email'
                    placeholder='example@gmail.com'
                    name='email'
                    setIsValid={setIsEmailValid}
                    data={data}
                    setData={setData}
                    required
                    />
                    <ValidateInput 
                    type='number'
                    label='Phone Number'
                    placeholder='8787588374'
                    name='phone'
                    setIsValid={setIsPhoneValid}
                    data={data}
                    setData={setData}
                    required
                    />
                    <PasswordInput 
                    label='Password'
                    placeholder='Ex : ******** '
                    name='password'
                    data={data}
                    setData={setData}
                    required
                    minLength={6}
                    maxLength={50}
                    />
                    <div className='mt-2'>
                        <button
                        type='submit' 
                        className="btn-primary py-3 sm:px-12 px-6 w-full"
                        disabled={loading || !isEmailValid || !isPhoneValid}
                        >
                            {
                                loading 
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Sign up'
                            }
                        </button>

                        <div className='text-sm text-dark mt-6 text-center flex items-center justify-center gap-1'>
                            <span>Already have an account ? </span> 
                            <button 
                            className='text-gradient'
                            onClick={() => navigate('/login')}
                            disabled={loading}
                            >
                                Sign in
                            </button>
                        </div>
                    </div>
                </form> */}
            </div>
        </div>
    )
}

export default Register