import OrderStatus from 'components/global/OrderStatus';
import BookingStatusSelectBox from 'components/bookingManagement/BookingStatusSelectBox';
import moment from 'moment';
import { memo, useState } from 'react';
import Axios from 'config/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import filterBookingStatuses from 'utils/filterBookingStatuses';

const statuses = ['pending' , 'accepted' , 'picked' , 'completed' , 'cancelled' , 'declined'];

const AboutBooking = ({ status , setStatus , booking , setBooking }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [updateLoading , setUpdateLoading] = useState(false);
    const { user } = useSelector(state => state.auth);


    const updateHandler = async () => {
        if(booking?.customer?.kycStatus !== 'approved' && status !== 'declined') {
            return toast.error('The user\'s KYC has not been approved yet. You cannot update this booking until the KYC is approved.')
        }
        if(window.confirm(`Are you sure. You want to update booking status?`)) {
            try {
                setUpdateLoading(true);
                const { data : { data : { message , doc } } } = await Axios.put(`/booking/${id}` , { status } , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                toast.success(message);
                setBooking(prev => ({...prev , status : doc?.status }));
                setUpdateLoading(false);
            } catch (error) {
                setUpdateLoading(false);
                toastError(error);
            }
        }
    }

    return (
        <div className='shadow-bg p-4 flex lg:gap-4 gap-10 justify-between lg:flex-row flex-col'>
            <div>
                <div className="flex items-center gap-3">
                    <h3 className="text-lg font-semibold text-primary">
                        About 
                    </h3>
                    {
                        booking?.withDriver && 
                        <div className='bg-green-500 text-white bg-opacity-90 px-2 py-1 rounded-md text-xs'>
                            With Driver
                        </div>
                    }
                </div>
                <div className='mt-4 flex flex-col gap-3'>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[100px]'>
                            Booking Id :
                        </h6>
                        <span className='text-gray-600'>#{booking?._id}</span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[100px]'>
                            Placed on :
                        </h6>
                        <span className='text-gray-600'>
                            {moment(booking?.createdAt).format('DD MMM YYYY hh:mm A')}
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[100px]'>
                            Status :
                        </h6>
                        <span className='text-gray-600'>
                            <OrderStatus status={booking?.status} />
                        </span>
                    </div>
                    {
                        booking?.pickedAt && 
                        <div className='text-sm flex items-center gap-2'>
                            <h6 className='font-semibold w-[100px]'>
                                Picked At :
                            </h6>
                            <span className='text-gray-600'>
                                {moment(booking?.pickedAt).format('DD MMM YYYY hh:mm A')}
                            </span>
                        </div>
                    }
                    {
                        booking?.completedAt && 
                        <div className='text-sm flex items-center gap-2'>
                            <h6 className='font-semibold w-[100px]'>
                                Completed At :
                            </h6>
                            <span className='text-gray-600'>
                                {moment(booking?.completedAt).format('DD MMM YYYY hh:mm A')}
                            </span>
                        </div>
                    }
                    {
                        booking?.status === 'cancelled' 
                        ?
                            <div className='font-semibold text-red-500  mt-2'>
                            This booking is cancelled by {booking?.cancelledBy?._id === user?._id ? 'You' : booking?.cancelledBy?._id === booking?.customer?._id ? 'User' : 'Admin'}
                            </div>
                            :
                        booking?.status === 'completed' 
                        ?
                            <div className='font-semibold text-green-500  mt-2'>
                            This booking is completed
                            </div>
                        : ''
                    }
                </div>
            </div>
            {
                booking?.status === 'pending' || booking?.status === 'accepted' || booking?.status === 'picked'
                ?
                <div className='flex flex-col gap-4 justify-between'>
                    <div className='flex items-center gap-2'>
                        <h6 className='font-semibold text-sm'>Update Status</h6>
                        <select
                        onChange={e => {
                            if(e.target.value === 'picked') {
                                navigate(`/booking-management/upload-pick-up-photos/${id}`)
                            }else if (e.target.value === 'completed') {
                                navigate(`/booking-management/upload-drop-off-photos/${id}`)
                            }
                            setStatus(e.target.value)
                        }}
                        className='select-box'
                        value={status}
                        >
                            <option
                            key={status}
                            value={status}
                            >
                                {status}
                            </option>
                            {
                                filterBookingStatuses(booking?.status , booking?.pickupDate , booking?.returnDate)?.map((status) => (
                                    <option
                                    key={status}
                                    value={status}
                                    >
                                        {status}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='flex items-end lg:justify-end'>
                        <button 
                        className="btn-primary py-2 px-6 text-sm"
                        onClick={updateHandler}
                        disabled={updateLoading || status === booking?.status}
                        >
                            {
                                updateLoading
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Save'
                            }
                        </button>
                    </div>
                </div>
                : ''
            }
        </div>
    )
}

export default memo(AboutBooking)