import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import FormControl from 'components/global/form/FormControl'
import WithdrawBankDetails from 'components/withdraw/WithdrawBankDetails'
import Axios from 'config/api'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import fetcher from 'utils/fetcher'
import formatAmount from 'utils/formatAmount';
import toastError from 'utils/toastError'
import * as Yup from 'yup';

const validations = Yup.object().shape({
    withdrawAmount : Yup.number()
        .required('Withdrawal amount is required')
        .positive('Withdrawal amount must be a positive number')
});

const initState = {
    withdrawAmount : ''
}

const Withdraw = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    const [settings , setSettings] = useState(null);
    const [userBank , setUserBank] = useState(null);
    const [loading , setLoading] = useState(false);

    const queryKey = 'fetch-settings-with-bank';
    const { isLoading , data } = useQuery(queryKey, () => {
        return fetcher('/setting/bank', user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { settings , bank } } } = data;
            setSettings(settings);
            setUserBank(bank);
        }
    } , [data]);

    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            const { data : { data : { message } } }  = await Axios.post('/withdraw' , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            setLoading(false);
            navigate('/withdrawals')
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='Withdraw' showIcon={false} />
                <BackBtn />
            </div>
            <div className='mt-6 shadow-bg p-4 py-8 rounded-lg'>
                <div className='flex items-center justify-center flex-col gap-1'>
                    <h3 className='text-lg'>Available Balance</h3>
                    <p className='sm:text-3xl text-primary font-semibold gradient-text text-xl'>INR:{formatAmount(user?.wallet?.totalBalance)}</p>
                </div>
            </div>
            {
                isLoading
                ? 
                    <Loader />
                : 
                    <>
                        <div>
                            <WithdrawBankDetails 
                            userBank={userBank} 
                            setUserBank={setUserBank}
                            />
                        </div>
                        {
                            userBank && 
                            <div className='shadow-bg p-4 mt-6'>
                                <Formik
                                initialValues={initState}
                                validationSchema={validations}
                                onSubmit={handleSubmit}
                                >
                                    {
                                        formik => {
                                            return (
                                                <Form>
                                                    <FormControl
                                                        type='number'
                                                        control='input'
                                                        label='Withdraw Amount'
                                                        placeholder='Enter withdraw amount'
                                                        name='withdrawAmount'
                                                        formik={formik}
                                                        min={settings?.minWithdraw}
                                                        max={user?.wallet?.totalBalance}
                                                    />
                                                    <button 
                                                    className="btn-primary py-2 px-6 block mt-4"
                                                    type='submit'
                                                    disabled={loading || !formik.isValid}
                                                    >
                                                        {
                                                            loading 
                                                            ? 
                                                                <ClipLoader size={20} color='white' />
                                                            : 
                                                                'Submit'
                                                        }
                                                    </button>
                                                </Form>
                                            )
                                        }
                                    }
                                </Formik>
                            </div>
                        }
                    </>
            }
        </Layout>
    )
}

export default Withdraw