import { createSlice } from "@reduxjs/toolkit";



const withdrawSlice = createSlice({
    name : 'withdraw' ,
    initialState : {
        docs : [] ,
        loading : false , 
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
    } ,
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
    }
})

export const { setLoading , setCurrentPage , setPages , setDocsCount , setDocs } = withdrawSlice.actions;
export default withdrawSlice.reducer;