import Sidebar from "components/global/sidebar";
import { Routes , Route, useLocation, Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Dashboard from "pages/dashboard";
import Bookings from "pages/bookingManagement";
import Login from "pages/auth/Login";
import Cars from "pages/carsManagement";
import AddNewCar from "pages/carsManagement/AddNewCar";
import EditCar from "pages/carsManagement/EditCar";
import CarDetails from "pages/carsManagement/CarDetails";
import Contact from "pages/contact";
import Profile from "pages/profile";
import Register from "pages/auth/Register";
import BookingDetails from "pages/bookingManagement/BookingDetails";
import { useApi } from "config/api";
import ForgotPassword from "pages/auth/ForgotPassword";
import VerifyOtp from "pages/auth/VerifyOtp";
import ResetPassword from "pages/auth/ResetPassword";
import UpdatePassword from "pages/updatePassword";
import ProtectedRoute from "ProtectedRoute";
import { useSelector } from "react-redux";
import NotificationToast from "components/global/NotificationToast";
import { useEffect, useState } from "react";
import { onMessageListener } from "utils/firebase";
import useNotifyPermission from "hooks/useNotifyPermission";
import WebcamCapture from "pages/WebCam";
import UploadPickUpPhotos from "pages/bookingManagement/UploadPickUpPhotos";
import UploadDropOffPhotos from "pages/bookingManagement/UploadDropOffPhotos";
import WithdrawHistory from "pages/withdrawHistory";
import Withdraw from "pages/withdraw";
import ManageAvailability from "pages/carsManagement/ManageAvailability";


function App() {
    const api = useApi();
    const notificationPermission = useNotifyPermission();
    const { user } = useSelector(state => state.auth);
    const location = useLocation();

    const isAuthPage = () => {
        if(location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgot-password' || location.pathname === '/verify-otp' || location.pathname === '/reset-password') {
            return true;
        }
        return false;
    }

    const [notification, setNotification] = useState({ title: "", body: "" });
    const notify = () => toast(<NotificationToast notification={notification} /> , {
        position : "bottom-right" ,
        autoClose : 5000 ,
        hideProgressBar : false
    });

    useEffect(() => {
        if (notification?.title) {
            notify();
        }
    }, [notification]);

    onMessageListener()
    .then((payload) => {
        setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log("failed: ", err));

    useEffect(() => {       
        window.scrollTo(0,0)
    }, [location.pathname]);

    return (
        <div className="space">
            <ToastContainer 
                style={{fontSize: 15}}
                position="top-center"
                autoClose={3000}
                closeOnClick
                pauseOnHover
            />
            {!isAuthPage() && <Sidebar />}
            <Routes>
                <Route path='/' element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } 
                />
                <Route path='/dashboard' element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/login' 
                element={user ? <Navigate to='/dashboard' /> : <Login />} 
                />
                <Route 
                path='/register' 
                element={user ? <Navigate to='/dashboard' /> : <Register />} 
                />
                <Route 
                path='/forgot-password' 
                element={user ? <Navigate to='/dashboard' /> : <ForgotPassword />} 
                />
                <Route 
                path='/verify-otp' 
                element={<VerifyOtp />} 
                />
                <Route 
                path='/reset-password' 
                element={user ? <Navigate to='/dashboard' /> : <ResetPassword />} 
                />
                <Route 
                path='/booking-management'
                element={
                    <ProtectedRoute>
                        <Bookings />
                    </ProtectedRoute>}
                />
                <Route 
                path='/booking-management/booking-details/:id'
                element={
                    <ProtectedRoute>
                        <BookingDetails />
                    </ProtectedRoute>}
                />
                <Route 
                path='/booking-management/upload-pick-up-photos/:id'
                element={
                    <ProtectedRoute>
                        <UploadPickUpPhotos />
                    </ProtectedRoute>}
                />
                <Route 
                path='/booking-management/upload-drop-off-photos/:id'
                element={
                    <ProtectedRoute>
                        <UploadDropOffPhotos />
                    </ProtectedRoute>}
                />
                <Route 
                path='/cars-management'
                element={
                    <ProtectedRoute>
                        <Cars />
                    </ProtectedRoute>}
                />
                <Route 
                path='/cars-management/manage-availability/:id'
                element={
                    <ProtectedRoute>
                        <ManageAvailability />
                    </ProtectedRoute>}
                />
                <Route 
                path='/cars-management/add-new'
                element={
                    <ProtectedRoute>
                        <AddNewCar />
                    </ProtectedRoute>}
                />
                <Route 
                path='/cars-management/edit/:id'
                element={
                    <ProtectedRoute>
                        <EditCar />
                    </ProtectedRoute>}
                />
                <Route 
                path='/cars-management/details/:id'
                element={
                    <ProtectedRoute>
                        <CarDetails />
                    </ProtectedRoute>}
                />
                <Route 
                path='/contact'
                element={
                    <ProtectedRoute>
                        <Contact />
                    </ProtectedRoute> }
                />
                <Route 
                path='/profile'
                element={
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute> }
                />
                <Route 
                path='/update-password'
                element={
                    <ProtectedRoute>
                        <UpdatePassword />
                    </ProtectedRoute> }
                />
                <Route 
                path='/cam'
                element={<WebcamCapture />}
                />
                <Route 
                path='/withdrawals'
                element={<WithdrawHistory />}
                />
                <Route 
                path='/withdraw'
                element={<Withdraw />}
                />

                
                {/* <Route 
                path='/analytics'
                element={<AnalyticsAndReporting />}
                /> */}
            </Routes>
        </div>
    );
}

export default App;
