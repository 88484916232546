import SmallCarImg from 'assets/images/smallCar.png';
import RequestStatus from 'components/global/RequestStatus';
import { baseURL } from 'config/api';
import { useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { car_delete } from 'redux/actions/carActions';

const Car = ({ car }) => {
    const dispatch = useDispatch();
    const { deleteLoading } = useSelector(state => state.car);
    const [deletedId , setDeletedId] = useState('');

    const deleteHandler = (id) => {
        if(window.confirm('Are you sure? You want to delete this car?')) {
            dispatch(car_delete(id));
        }
    }

    return (
        <div className='shadow-bg p-4 hover:border-primary '>
            {
                car?.images?.length > 0 &&
                <LazyLoad height={200}>
                    <div className='flex items-center justify-center'>
                        <img 
                        src={baseURL + '/cars/' + car?.images[0]} 
                        alt="car"
                        className='w-full h-[150px] object-cover rounded-lg'
                        />
                    </div>
                </LazyLoad>
            }
            <div className='mt-6 flex flex-col gap-3'>
                <div className="flex items-center justify-between gap-4">
                    <h3 className='font-semibold text-lg'>
                        {car?.name}
                    </h3>
                    <RequestStatus status={car?.status} />
                </div>
                <div className='text-sm flex justify-between'>
                    <h6>Price</h6>
                    <p>&#8377; <span className='text-base font-semibold'>{car?.rentPerHour}</span>/per hour</p>
                </div>
                <div className='text-sm flex justify-between'>
                    <div className='flex items-center gap-1'>
                        <span>{car?.ratingsAvg}</span> 
                        <i className="uil uil-star text-yellow-500 text-base"></i>
                    </div>
                    <div>
                        {car?.ratingsCount} <span>(Reviews)</span>
                    </div>
                </div>
                <div>
                    <div className='flex items-center gap-4'>
                        <button 
                        className="border border-red-500 rounded-md py-1.5 px-6 text-red-500 flex items-center justify-center gap-2 hover:text-pure hover:bg-red-500 duration-200 w-full"
                        onClick={() => {
                            setDeletedId(car?._id)
                            deleteHandler(car?._id);
                        }}
                        disabled={deleteLoading}
                        >
                            {
                                deleteLoading && deletedId === car?._id
                                ?
                                    <ClipLoader size={20} color='red' />
                                : 
                                    <>
                                        <i className="uil uil-trash"></i>
                                        <span>Delete</span>
                                    </>
                            }
                        </button>
                        <Link
                        to={`/cars-management/edit/${car?._id}`} 
                        className="border border-green-500 rounded-md py-1.5 px-6 text-green-500 flex items-center justify-center gap-2 hover:text-pure hover:bg-green-500 duration-200 w-full">
                            <i className="uil uil-pen"></i>
                            <span>Edit</span>
                        </Link>
                    </div>
                    <div className='mt-4 flex items-center gap-4'>
                        <Link 
                        to={`/cars-management/manage-availability/${car?._id}`}
                        className="bg-primary rounded-md text-pure border border-transparent hover:bg-transparent hover:text-primary hover:border-primary duration-200 ease-in-out py-2 px-6 flex items-center justify-center gap-2 w-full">
                            <i className="uil uil-setting"></i>
                            <span>Manage Availability</span>
                        </Link>
                        <Link 
                        to={`/cars-management/details/${car?._id}`}
                        className="btn-primary py-2 px-6 flex items-center justify-center gap-2 w-full">
                            <i className="uil uil-eye"></i>
                            <span>Details</span>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Car