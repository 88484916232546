import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
    name : 'car' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        loading : false , 
        updateLoading : false , 
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 , 
        status : '' ,
        duration : '' , 
        keyword : ''
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        updateDoc (state , action) {
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
            state.docDetails = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } , 
        setStatus (state , action) {
            state.status = action.payload;
        } , 
        setDuration (state , action) {
            state.duration = action.payload;
        } , 
        setKeyword (state , action) {
            state.keyword = action.payload;
        } , 
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setUpdateLoading , setCurrentPage , setPages , updateDoc , setDocsCount , setStatus , setKeyword , setDuration
} = bookingSlice.actions;

export default bookingSlice.reducer;