import Layout from 'components/global/Layout';
import React, { useState } from 'react';
import Capture from './Capture';

function WebcamCapture() {
    const [pickedPhotos, setPickedPhotos] = useState({
        frontRightCorner: '',
        frontLeftCorner: '',
        fullFront: '',
        rearRightCorner: '',
        rearLeftCorner: '',
        fullRear: '',
        leftDoorPhoto: '',
        rightDoorPhoto: '',
        bootSpacePhoto: '',
        interiorFront: '',
        interiorBack: ''
    });



    return (
        <Layout>
            <div className='grid 2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4'>
                <PhotoItem
                title='Front Right Corner Photo'
                setImage={setPickedPhotos}
                name='frontRightCorner'
                image={pickedPhotos.frontRightCorner}
                />
                <PhotoItem
                title='Front Left Corner Photo'
                setImage={setPickedPhotos}
                name='frontLeftCorner'
                image={pickedPhotos.frontLeftCorner}
                />
            </div>
        </Layout>
    );
}


const PhotoItem = ({ title , name , setImage , image }) => {
    const [showCam , setShowCam] = useState(false);
    return (
        <div 
        className='flex-1 w-full xl:h-[300px] h-[250px] border rounded-lg relative flex items-center justify-center'
        
        >
            {
                image 
                ? 
                    <img src={image} alt={title} className='w-full h-full' />
                : 
                    <div className='flex flex-col gap-4'>
                        {title}
                        <button className="btn-primary py-2 px-4" onClick={() => {
                            setShowCam(true)
                        }}>
                            Click Photo
                        </button>
                    </div>
            }

            {
                showCam && <Capture name={name} setImage={setImage} setShowCam={setShowCam} />
            }
        </div>
    )
}

export default WebcamCapture;

