import SmallCarImg from 'assets/images/smallCar.png';
import { baseURL } from 'config/api';
import { memo } from 'react';
import { Link } from 'react-router-dom';

const CarDetails = ({ booking }) => {
    return (
        <div className='shadow-bg p-4'>
            <div>
                <h3 className="text-lg font-semibold text-primary">
                    Car Details
                </h3>
            </div>
            <div className='flex gap-6 justify-between lg:flex-row flex-col mt-6'>
                {
                    booking?.car?.images?.length > 0 &&
                    <div className='flex-[0.3] flex items-center'>
                        <img 
                        src={ baseURL + '/cars/' + booking?.car?.images[0]} 
                        alt="" 
                        className='w-[200px] object-contain rounded-md'
                        />
                    </div>
                }
                <div className='flex-[0.4] flex flex-col gap-3'>
                    <h3 className='text-lg font-bold'>
                        {booking?.car?.name}
                    </h3>
                    <div className='flex gap-8'>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-user"></i>
                                <span className='text-xs'>{booking?.car?.seats} Seats</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-suitcase-alt"></i>
                                <span className='text-xs'>3 Bags</span>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-auto-flash"></i>
                                <span className='text-xs capitalize'>
                                    {booking?.car?.transmissionType}
                                </span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-dashboard"></i>
                                <span className='text-xs capitalize'>
                                    {booking?.car?.mileage} mileage
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-[0.3] flex lg:items-end lg:justify-end flex-col'>
                    <span className='text-[13px]'>Rent Per Hour</span>
                    <h6 className='text-xl font-bold mt-1 mb-2'>
                        Rs {booking?.rentPerHour}
                    </h6>
                </div>
            </div>
        </div>
    )
}

export default memo(CarDetails)