import PopupLayout from 'components/global/PopupLayout'
import FormControl from 'components/global/form/FormControl';
import Axios from 'config/api';
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import fetcher from 'utils/fetcher';
import toastError from 'utils/toastError';
import bankValidations from 'validations/BankValidations';

const initState = {
    bankName: '',
    accountNo: '',
    accountHolder: '',
    IFSC_code: '',
};

const EditBankPopup = ({ userBank , setUserBank , setShowPopup  }) => {
    const [bankData, setBankData] = useState(userBank);
    const [loading , setLoading] = useState(false);
    const { user } = useSelector(state => state.auth);



    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            const { data : { data : { doc , message } } } = await Axios.put(`/withdraw-bank/${userBank?._id}` , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            setUserBank(doc);
            setLoading(false);
            setShowPopup(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }


    return (
        <PopupLayout setShowPopup={setShowPopup}>
            <h3 className='text-center font-semibold md:text-2xl text-xl'>
                Edit Withdraw Details
            </h3>
            <Formik
                initialValues={bankData}
                validationSchema={bankValidations}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {
                    (formik) => {
                        return (
                            <Form
                                className='flex flex-col gap-6 py-8'
                            >
                                <FormControl
                                    control='input'
                                    label='Bank Name'
                                    placeholder='Ex : State bank of India'
                                    name='bankName'
                                    formik={formik}
                                />
                                <FormControl
                                    control='input'
                                    label='Account Holder Name'
                                    placeholder='Ex : Sumant Kumar'
                                    name='accountHolder'
                                    formik={formik}
                                />
                                <FormControl
                                    control='input'
                                    label='Account Number'
                                    placeholder='Ex : 09403859345434'
                                    name='accountNo'
                                    formik={formik}
                                />
                                <FormControl
                                    control='input'
                                    label='IFSC_code'
                                    placeholder='Enter your bank IFSC code'
                                    name='IFSC_code'
                                    formik={formik}
                                />
                                <div>
                                    <button
                                        type='submit'
                                        className='btn-primary py-2.5 px-12 w-full'
                                        disabled={loading || !formik.isValid}
                                    >
                                        {
                                            loading 
                                            ? 
                                                <ClipLoader 
                                                size={20} 
                                                color='white' 
                                                />
                                            : 
                                                'Update'
                                        }
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
            
        </PopupLayout>
    )
}

export default EditBankPopup