import './styles.css';
import { useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import useClickOutside from 'utils/clickOutside';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/actions/authActions';

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarRef = useRef();
    const location = useLocation();
    const { showDrawer , setShowDrawer } = useDrawerContext();

    useClickOutside(sidebarRef , () => setShowDrawer(false))

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

    const logoutHandler = () => {
        dispatch(logout(navigate));
    }

    return (
        <div className=''>
            {
                showDrawer && 
                <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-10'>
                </div>
            }
            <div 
            className={`sidebar ${showDrawer ? 'show' : '' } fixed top-0 md:left-0 -left-[200%] w-[270px]  overflow-auto py-4 z-50 border-r bg-pure`} 
            ref={sidebarRef}
            >
                <div className='overflow-auto'>
                    <div className='flex items-center justify-center border-b pb-4 text-gradient text-2xl font-bold'>
                        <Link to='/'>
                            Rent&Drive                       
                        </Link>
                    </div>
                    <ul className='sideMenu-list mt-6 text-dark min-h-screen h-full'>
                        <li 
                        className={`${isActive('' , true) ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/'>   
                                <i className="uil uil-apps"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('booking-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/booking-management' >
                                <i className="uil uil-shopping-cart"></i>
                                <span>Booking Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('cars-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/cars-management'>   
                                <i className="uil uil-chart-pie-alt"></i>
                                <span>Cars Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('withdrawals') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/withdrawals'>   
                                <i className="uil uil-usd-circle"></i>
                                <span>Withdrawals</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('contact') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/contact'>   
                                <i className="uil uil-phone"></i>
                                <span>Contact Us</span>
                            </Link>
                        </li>
                        {/* <li 
                        className={`${isActive('profile') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/profile'>   
                                <i className="uil uil-user-circle"></i>
                                <span>Profile Setup</span>
                            </Link>
                        </li> */}
                        <li 
                        className={`sideMenu-item signout`}
                        onClick={logoutHandler}
                        >
                            <i className="uil uil-signout"></i>
                            <span>Sign Out</span>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    )
}

export default Sidebar